<template>
  <div>
    <div class="table-head">
      <div class="table-search">
        <el-space>
          <div>
            <span>群发名称：</span>
            <el-input
              v-model="searchOption.key"
              placeholder="请输入"
            ></el-input>
          </div>
          <div>
            <span>状态：</span>
            <el-select v-model="searchOption.status" placeholder="全部">
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <span>开始时间：</span>
            <el-date-picker
              v-model="searchOption.dateValue"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </el-space>
        <div>
          <el-button type="primary" @click="searchAction">搜索</el-button>
          <el-button @click="resetAction">重置</el-button>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
      <div class="list-title">
        <div>
          共<span>{{ tableData.length || 0 }}</span
          >条群发消息
        </div>
        <el-button type="primary" @click="createAction">新建单聊</el-button>
      </div>

      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="Name" label="单聊名称"></el-table-column>
        <el-table-column label="进度" width="120">
          <template #default="scope">
            <div class="fail-wrap">
              <p>{{ scope.row.Process }}%</p>
              <el-slider
                v-model="scope.row.Process"
                :min="0"
                :max="100"
                disabled
                class="slider"
              ></el-slider>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Status" label="状态" width="60">
          <template #default="scope">
            <div class="fail-wrap">
              <div>{{ changeStatus(scope.row.Status) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="ExpectedSendTime"
          label="开始时间"
        ></el-table-column>
        <el-table-column
          prop="ActualLastSendTime"
          label="发送完成时间"
        ></el-table-column>
        <el-table-column
          prop="RobotCount"
          label="助手"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="TotalCount"
          label="发送数"
          width="60"
        ></el-table-column>
        <el-table-column
          prop="SendCount"
          label="成功数"
          width="60"
        ></el-table-column>
        <el-table-column label="失败数" width="80">
          <template #default="scope">
            <div class="fail-wrap">
              <div>{{ scope.row.FailureCount }}</div>
              <div v-if="scope.row.FailureCount > 0">
                (<span
                  style="cursor: pointer"
                  @click="checkFailedAction(scope.row)"
                  >原因</span
                >)
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="WaitingCount"
          label="等待数"
          width="60"
        ></el-table-column>
        <el-table-column label="操作" width="220">
          <template #default="scope">
            <el-button
              type="primary"
              @click="checkDetail(scope.row,1)"
              v-if="changeStatus(scope.row.Status) == '未开始'"
              >编辑</el-button
            >
            <el-button type="primary" @click="checkDetail(scope.row)" v-else>详情</el-button>
            <el-button type="primary" @click="copyAction(scope.row)" v-if="changeStatus(scope.row.Status) != '进行中'">复制</el-button>
            <el-button type="primary" @click="stopAction(scope.row)" v-else>终止</el-button>
            <el-button
              type="primary"
              @click="cancelAction(scope.row)"
              v-if="changeStatus(scope.row.Status) == '未开始'"
              >取消</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <TableFoot
        :pagination="pageObj"
        @changePage="changePage"
        @changeSize="changeSize"
      />
      <SendFailedCop ref="sendFailedRef" @onConfirm="onSureSingGroup"/>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import SendFailedCop from "@/components/SendFailedCop";
import { ElMessageBox, ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  GetPrivateSendList,
  CancelPrivateSend,
  GetPrivateSendDetail,
  CreatePrivateSend,
  PrivateSendStop
} from "@/helper/group";
import tools from "@/assets/js/tools";
export default {
  name: "SingleSend",
  components: {
    TableFoot,
    SendFailedCop,
  },
  beforeRouteEnter(to, from, next){
        next(vm=>{
            if(from.path == '/singlesend/create'){
                to.meta.getStore = true
            }
        })
    },
  beforeRouteLeave(to, from, next){
        this.$store.commit("SingleSend/setData",{
            pageObj:this.pageObj,
            searchOption:this.searchOption,
        })
        next()
  },
  setup() {
    const router = useRouter();
    const sendFailedRef = ref(null);
    const tableData = ref([]);
    const store = useStore()
    const route = useRoute()

    onMounted(() => {
      resetStore()
      searchAction();
    });
    function resetStore(){
        if(route.meta.getStore&& store.state.SingleSend.pageObj&&store.state.SingleSend.searchOption){
            pageObj.TotalRow= store.state.SingleSend.pageObj.TotalRow,
            pageObj.PageIndex= store.state.SingleSend.pageObj.PageIndex,
            pageObj.PageSize=store.state.SingleSend.pageObj.PageSize,
            searchOption.key = store.state.SingleSend.searchOption.key
            searchOption.status = store.state.SingleSend.searchOption.status
            searchOption.dateValue = store.state.SingleSend.searchOption.dateValue
        }
    }

    //创建
    function createAction() {
      router.push({ name: "CreateSingleSend", query: {isEdit:1} });
    }

    //查看详情
    function checkDetail(item,isEdit) {
      router.push({ name: "CreateSingleSend", query: { SID: item.ID,isEdit:isEdit||0} });
    }
    //复制
    function copyAction(item) {
       router.push({ name: "CreateSingleSend", query: { SID: item.ID,IsCopy:true,isEdit:1 } });
    }
    //终止
    function stopAction(item){
      ElMessageBox.confirm(`该发送任务中未发送的将立即停止发送，已发送成功的将无法撤回，您确定要终止吗？？`, "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          PrivateSendStop({ PrivateSendID: item.ID }).then((res) => {
            ElMessage({
              type: "success",
              message: "取消成功!",
            });
            searchAction();
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    }
    

    //取消
    function cancelAction(item) {
      ElMessageBox.confirm(`确认取消当前单发？`, "确认提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          CancelPrivateSend({ PrivateSendID: item.ID }).then((res) => {
            ElMessage({
              type: "success",
              message: "取消成功!",
            });
            searchAction();
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    }

    //失败弹框
    function checkFailedAction(item) {
      sendFailedRef.value.initCop(item, 1);
    }

    /**********************搜索***************************/
    const statusList = [
      { label: "全部", value: -1 },
      { label: "未开始", value: 0 },
      { label: "进行中", value: 1 },
      { label: "已完成", value: 2 },
      { label: "已取消", value: 3 },
      { label: "部分成功", value: 11 },
      { label: "已终止", value: 4 },
    ];
    const searchOption = reactive({
      key: "",
      status: -1,
      dateValue: "",
    });
    //转换状态
    function changeStatus(status) {
      if (status == -1) {
        return "全部";
      } else if (status == 0) {
        return "未开始";
      } else if (status == 1) {
        return "进行中";
      } else if (status == 2) {
        return "已完成";
      } else if (status == 3) {
        return "已取消";
      } else if (status == 11) {
        return "部分成功";
      } else if (status == 4) {
        return "已终止";
      }
    }
    //执行搜索
    function searchAction() {
      let start = "";
      let end = "";
      if (searchOption.dateValue) {
        start = tools.GMTToStr(searchOption.dateValue[0]);
        end = tools.GMTToStr(searchOption.dateValue[1]);
      }
      let param = {
        PageNum: pageObj.PageIndex,
        PageSize: pageObj.PageSize,
        Type: searchOption.status,
        Name: searchOption.key,
        Start: start,
        End: end,
      };
      GetPrivateSendList(param).then((res) => {
        pageObj.TotalRow = res.count;
        tableData.value = res.list;
      });
    }

    //重置条件
    function resetAction() {
      searchOption.key = "";
      searchOption.status = -1;
      searchOption.dateValue = "";
      pageObj.PageIndex = 1,
      searchAction();
    }

    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }
    function onSureSingGroup(){
      searchAction();
    }
    return {
      onSureSingGroup,
      tableData,
      searchOption,
      statusList,
      resetAction,
      searchAction,
      changePage,
      changeSize,
      pageObj,
      createAction,
      sendFailedRef,
      checkFailedAction,
      checkDetail,
      copyAction,
      cancelAction,
      changeStatus,
      stopAction
    };
  },
};
</script>
<style lang="scss" scoped>
.fail-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    width: 34px;
  }
  span {
    color: $color-common;
  }
}

.list-title div span {
  color: $color-common;
}

.slider {
  width: 60px;

  :deep() .el-slider__bar {
    background-color: $color-common;
  }

  :deep() .el-slider__button {
    width: 10px;
    height: 10px;
    // border:none;
  }
}
</style>